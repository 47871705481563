import { defineComponent as _defineComponent } from 'vue'
import BaseLayout from "@/layouts/template/BaseLayout.vue";
import DotHeader from "@/layouts/inc/Header.vue";
import DotFooter from "@/layouts/inc/Footer.vue";

export default /*@__PURE__*/_defineComponent({
  __name: 'DefaultLayout',
  setup(__props, { expose: __expose }) {
  __expose();


const __returned__ = { BaseLayout, DotHeader, DotFooter }
Object.defineProperty(__returned__, '__isScriptSetup', { enumerable: false, value: true })
return __returned__
}

})