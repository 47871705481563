import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, withModifiers as _withModifiers, createElementVNode as _createElementVNode } from "vue"

const _hoisted_1 = { "aria-label": "Main Menu" }
const _hoisted_2 = {
  class: "menu-list",
  role: "menubar"
}
const _hoisted_3 = ["aria-current", "tabindex", "onKeydown", "onClick"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("nav", _hoisted_1, [
    _createElementVNode("ul", _hoisted_2, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($setup.state.menuList, (menu, index) => {
        return (_openBlock(), _createElementBlock("li", {
          key: menu.MENU_ID,
          class: "menu-item",
          role: "none"
        }, [
          _createElementVNode("a", {
            "aria-current": $setup.isActive(menu.MENU_URL) ? 'page' : undefined,
            tabindex: index === 0 ? 0 : -1,
            href: "#",
            role: "menuitem",
            onKeydown: ($event: any) => ($setup.handleKeyDown($event, index)),
            onClick: _withModifiers(($event: any) => ($setup.navigateToMenu(menu.MENU_URL)), ["prevent"])
          }, _toDisplayString(menu.MENU_NAME), 41 /* TEXT, PROPS, NEED_HYDRATION */, _hoisted_3)
        ]))
      }), 128 /* KEYED_FRAGMENT */))
    ])
  ]))
}