import { createCommentVNode as _createCommentVNode, renderSlot as _renderSlot, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock, createVNode as _createVNode } from "vue"

const _hoisted_1 = {
  key: 0,
  ref: "headerSlot"
}
const _hoisted_2 = {
  key: 2,
  ref: "footerSlot"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_navigation_drawer = _resolveComponent("v-navigation-drawer")!
  const _component_v_container = _resolveComponent("v-container")!
  const _component_v_main = _resolveComponent("v-main")!
  const _component_v_app = _resolveComponent("v-app")!

  return (_openBlock(), _createBlock(_component_v_app, null, {
    default: _withCtx(() => [
      _createCommentVNode(" Header "),
      (_ctx.$slots.header)
        ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
            _renderSlot(_ctx.$slots, "header", {}, undefined, true)
          ], 512 /* NEED_PATCH */))
        : _createCommentVNode("v-if", true),
      _createCommentVNode(" Side Menu "),
      (_ctx.$slots.sideMenu)
        ? (_openBlock(), _createBlock(_component_v_navigation_drawer, {
            key: 1,
            class: "side-menu",
            width: "300"
          }, {
            default: _withCtx(() => [
              _renderSlot(_ctx.$slots, "sideMenu", {}, undefined, true)
            ]),
            _: 3 /* FORWARDED */
          }))
        : _createCommentVNode("v-if", true),
      _createCommentVNode(" Main Content "),
      _createVNode(_component_v_main, { scrollable: "" }, {
        default: _withCtx(() => [
          _createVNode(_component_v_container, { class: "container" }, {
            default: _withCtx(() => [
              _renderSlot(_ctx.$slots, "default", {}, undefined, true)
            ]),
            _: 3 /* FORWARDED */
          })
        ]),
        _: 3 /* FORWARDED */
      }),
      _createCommentVNode(" Footer "),
      (_ctx.$slots.footer)
        ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
            _renderSlot(_ctx.$slots, "footer", {}, undefined, true)
          ], 512 /* NEED_PATCH */))
        : _createCommentVNode("v-if", true)
    ]),
    _: 3 /* FORWARDED */
  }))
}