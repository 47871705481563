import { RouteRecordRaw } from "vue-router";
import SideMenuLayout from "@/layouts/SideMenuLayout.vue";

const routes: Array<RouteRecordRaw> = [
  {
    path: "/faq",
    meta: { layout: SideMenuLayout },
    children: [
      {
        path: "",
        name: "Dot Canvas FAQ",
        component: () =>
          import(/* webpackChunkName: "faq" */ "../views/faq/FaqView.vue"),
      },
      {
        path: "write",
        name: "Dot Canvas FAQ Write",
        component: () =>
          import(/* webpackChunkName: "faq" */ "../views/faq/FaqWrite.vue"),
      },
    ],
  },
];

export default routes;
