/**
 * plugins/index.ts
 *
 * Automatically included in `./src/main.ts`
 */

// Plugins
import vuetify from "./vuetify";
import router from "../router";
import store from "../store";
import i18n from "./i18n";
import CKEditor from "@ckeditor/ckeditor5-vue";

// Types
import type { App } from "vue";

export function registerPlugins(app: App) {
  app.use(vuetify);
  app.use(router);
  app.use(store);
  app.use(i18n);
  app.use(CKEditor);
}
