import { defineComponent as _defineComponent } from 'vue'
import { reactive } from "vue";


export default /*@__PURE__*/_defineComponent({
  __name: 'LangChangeBtn',
  setup(__props, { expose: __expose }) {
  __expose();

const state = reactive({
  locales: [
    { code: "ko", name: "한국어" },
    { code: "ar", name: "اللغة العربية" },
    { code: "cz", name: "Czech" },
    { code: "de", name: "German" },
    { code: "en", name: "Eng" },
    { code: "es", name: "Español" },
    { code: "fr", name: "Français" },
    { code: "ja", name: "日本語" },
    { code: "kz", name: "қазақ" },
    { code: "pt", name: "Português" },
    { code: "sv", name: "Svenska" },
  ],
});

const __returned__ = { state }
Object.defineProperty(__returned__, '__isScriptSetup', { enumerable: false, value: true })
return __returned__
}

})