/**
 * plugins/locales.ts
 *
 * Framework documentation: https://vue-i18n.intlify.dev`
 */

// Composables
import { createI18n } from "vue-i18n";
import { watch } from "vue";

const locales = loadLocales();
const messages: Record<string, Record<string, string>> = {};

locales.forEach((locale) => {
  const menu = require(`@/locales/${locale}/menu.json`);
  const label = require(`@/locales/${locale}/label.json`);
  const message = require(`@/locales/${locale}/message.json`);

  messages[locale] = {
    ...menu,
    ...label,
    ...message,
  };
});

const i18n = createI18n({
  legacy: false, // you must set `false`, to use Composition API
  locale: "en", // set locale
  fallbackLocale: "ko", // set fallback locale
  messages,
});

// 앱 초기화 시 로컬 스토리지에서 언어 설정을 가져와 적용
const initialLanguage = getLanguageSetting();
if (initialLanguage && locales.includes(initialLanguage)) {
  i18n.global.locale.value = initialLanguage as keyof typeof messages;
}

// 언어 설정을 로컬 스토리지에 저장하는 함수
function saveLanguageSetting(language: string) {
  localStorage.setItem("dot-locale-code", language);
}

// 언어 설정을 로컬 스토리지에서 가져오는 함수
function getLanguageSetting() {
  return localStorage.getItem("dot-locale-code");
}

// i18n 자식 디렉토리명을 가져옴
function loadLocales() {
  // Webpack의 require.context()를 사용하여 locales 디렉토리 내의 모든 파일을 가져옵니다.
  const context = require.context("@/locales", true, /\.json$/);

  // 파일 경로에서 디렉토리 이름만 추출합니다.
  const locales = context.keys().reduce((acc: string[], path) => {
    const match = path.match(/\/(.+?)\//);
    if (match && !acc.includes(match[1])) {
      acc.push(match[1]);
    }
    return acc;
  }, []);

  return locales;
}

// 언어 변경 시 로컬 스토리지에 설정을 저장
watch(
  () => i18n.global.locale.value,
  (newLocale: string) => {
    saveLanguageSetting(newLocale);
  }
);

export default i18n;
