import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, withCtx as _withCtx, createVNode as _createVNode, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-6e166926"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "container" }
const _hoisted_2 = { class: "box" }
const _hoisted_3 = { class: "side-nav" }
const _hoisted_4 = {
  key: 0,
  class: "flex-grow-1"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("ul", _hoisted_3, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($setup.menuList, (menu, index) => {
          return (_openBlock(), _createElementBlock("li", {
            key: index,
            class: "d-flex"
          }, [
            _createVNode(_component_router_link, {
              to: menu.link,
              class: _normalizeClass(["link", { 'disabled-link': menu.disabled }])
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.$t(menu.title)), 1 /* TEXT */)
              ]),
              _: 2 /* DYNAMIC */
            }, 1032 /* PROPS, DYNAMIC_SLOTS */, ["to", "class"]),
            (menu.type === 'blank')
              ? (_openBlock(), _createElementBlock("span", _hoisted_4, [
                  _createVNode($setup["OpenInNew"])
                ]))
              : _createCommentVNode("v-if", true)
          ]))
        }), 128 /* KEYED_FRAGMENT */))
      ])
    ])
  ]))
}