<template>
  <v-app-bar :elevation="0" class="header">
    <v-toolbar-title class="logo">
      <!-- 고객 지원 사이트로 이동 -->
      <router-link to="/" class="link">
        <img alt="Dot Inc." src="../../assets/logo.png" />
      </router-link>
      <!-- 고객 지원 사이트로 이동 -->
    </v-toolbar-title>
    <v-spacer></v-spacer>
    <btn-menu></btn-menu>
    <!-- <v-spacer></v-spacer> -->
    <!-- 메인 메뉴 버튼 -->
    <!-- <btn-menu></btn-menu> -->
    <!-- /메인 메뉴 버튼 -->
    <v-spacer></v-spacer>
    <btn-login></btn-login>
    <btn-lang-change></btn-lang-change>
  </v-app-bar>
</template>

<script setup lang="ts">
import BtnMenu from "@/components/header/MenuBtn.vue";
import BtnLangChange from "@/components/header/LangChangeBtn.vue";
import BtnLogin from "@/components/header/LoginBtn.vue";
</script>

<style scoped>
.header {
  padding: 10px 2%;
  border-bottom: 1px solid #d9d9d9;
  height: 84px;
}
.logo {
  flex: none;
  margin: 0;
}
</style>
