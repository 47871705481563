import { defineComponent as _defineComponent } from 'vue'
import "@/assets/css/layout.css";

export default /*@__PURE__*/_defineComponent({
  __name: 'BaseLayout',
  setup(__props, { expose: __expose }) {
  __expose();


const __returned__ = {  }
Object.defineProperty(__returned__, '__isScriptSetup', { enumerable: false, value: true })
return __returned__
}

})