import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode } from "vue"

const _hoisted_1 = { class: "menu-list" }
const _hoisted_2 = ["aria-disabled", "href"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createElementBlock("ul", _hoisted_1, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($setup.menuList, (menu, index) => {
      return (_openBlock(), _createElementBlock("li", {
        key: index,
        class: "menu-item"
      }, [
        (menu.type === 'router')
          ? (_openBlock(), _createBlock(_component_router_link, {
              key: 0,
              "aria-disabled": menu.disabled ? 'true' : undefined,
              to: menu.link
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString($setup.t(menu.title)), 1 /* TEXT */)
              ]),
              _: 2 /* DYNAMIC */
            }, 1032 /* PROPS, DYNAMIC_SLOTS */, ["aria-disabled", "to"]))
          : (menu.type === 'blank')
            ? (_openBlock(), _createElementBlock("a", {
                key: 1,
                "aria-disabled": menu.disabled ? 'true' : undefined,
                href: menu.link,
                class: "d-flex align-center",
                rel: "noopener noreferrer",
                target: "_blank"
              }, [
                _createTextVNode(_toDisplayString($setup.t(menu.title)) + " ", 1 /* TEXT */),
                _createVNode($setup["OpenInNew"])
              ], 8 /* PROPS */, _hoisted_2))
            : _createCommentVNode("v-if", true)
      ]))
    }), 128 /* KEYED_FRAGMENT */))
  ]))
}