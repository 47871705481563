import { defineComponent as _defineComponent } from 'vue'
import { $session } from "framework";
import { computed, reactive } from "vue";
import OpenInNew from "@/components/atoms/icon/OpenInNew.vue";
import { useI18n } from "vue-i18n";


export default /*@__PURE__*/_defineComponent({
  __name: 'SideMenu',
  setup(__props, { expose: __expose }) {
  __expose();

const { t } = useI18n();

const userRole = $session.getUserRoles()["dot-saas-support"];
const isAdmin = userRole?.includes("ADMIN") || false;

const state = reactive({
  isAdmin: isAdmin,
});

const menuList = computed(() => (state.isAdmin ? adminMenu : userMenu));

const userMenu = [
  {
    title: "공지사항",
    link: "/notice",
    type: "router",
    disabled: false,
  },
  {
    title: "유저 가이드",
    link: "https://dot-support.notion.site/fcd4b09c081b4cf4a3ae01d2442a32fe",
    type: "blank",
    disabled: false,
  },
  {
    title: "펌웨어 업데이트(USB)",
    link: "/update/firmware/connect",
    type: "router",
    disabled: false,
  },
  {
    title: "펌웨어 업데이트(Bluetooth)",
    link: "/update/firmware/bluetooth",
    type: "router",
    disabled: true,
  },
  {
    title: "자주 묻는 질문",
    link: "/faq",
    type: "router",
    disabled: false,
  },
];

const adminMenu = [
  {
    title: "공지사항",
    link: "/notice",
    type: "router",
    disabled: false,
  },
  {
    title: "유저 가이드",
    link: "https://dot-support.notion.site/fcd4b09c081b4cf4a3ae01d2442a32fe",
    type: "blank",
    disabled: false,
  },
  {
    title: "펌웨어 업데이트(USB)",
    link: "/update/firmware/connect",
    type: "router",
    disabled: false,
  },
  {
    title: "펌웨어 업데이트(Bluetooth)",
    link: "/update/firmware/bluetooth",
    type: "router",
    disabled: true,
  },
  {
    title: "자주 묻는 질문",
    link: "/faq",
    type: "router",
    disabled: false,
  },
];

const __returned__ = { t, userRole, isAdmin, state, menuList, userMenu, adminMenu, OpenInNew }
Object.defineProperty(__returned__, '__isScriptSetup', { enumerable: false, value: true })
return __returned__
}

})