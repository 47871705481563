/* eslint-disable */
import { RouteRecordRaw } from "vue-router";
import SideMenuLayout from "@/layouts/SideMenuLayout.vue";
import BlankLayout from "@/layouts/BlankLayout.vue";

const routes: Array<RouteRecordRaw> = [
  {
    path: "/voc",
    name: "Dot Canvas VOC",
    component: () => import(/* webpackChunkName: "home" */ "../views/voc/VocView.vue"),
    meta: { layout: SideMenuLayout },
  },
  {
    path: "/popup/voc",
    name: "Dot Canvas VOC Popup",
    component: () => import(/* webpackChunkName: "home" */ "../views/voc/VocView.vue"),
    meta: { layout: BlankLayout },
  },
];

export default routes;
