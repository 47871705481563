import { createVNode as _createVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_view = _resolveComponent("router-view")!

  return (_openBlock(), _createBlock($setup["BaseLayout"], null, {
    header: _withCtx(() => [
      _createVNode($setup["DotHeader"])
    ]),
    sideMenu: _withCtx(() => [
      _createVNode($setup["DotSideMenu"])
    ]),
    default: _withCtx(() => [
      _createVNode(_component_router_view)
    ]),
    _: 1 /* STABLE */
  }))
}