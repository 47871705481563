import { defineComponent as _defineComponent } from 'vue'
import BtnMenu from "@/components/header/MenuBtn.vue";
import BtnLangChange from "@/components/header/LangChangeBtn.vue";
import BtnLogin from "@/components/header/LoginBtn.vue";

export default /*@__PURE__*/_defineComponent({
  __name: 'Header',
  setup(__props, { expose: __expose }) {
  __expose();


const __returned__ = { BtnMenu, BtnLangChange, BtnLogin }
Object.defineProperty(__returned__, '__isScriptSetup', { enumerable: false, value: true })
return __returned__
}

})