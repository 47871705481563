/**
 * main.ts
 */

// Components
import App from "./App.vue";

import { $axios } from "framework";
import global from "global";

// Composables
import { createApp } from "vue";

// Plugins
import { registerPlugins } from "@/plugins";

const app = createApp(App);

registerPlugins(app);

app.mount("#app");

$axios.interceptorsRequest();
$axios.interceptorsResponse();

// ---------------------------------
// 통합인증 및 메뉴/권한 설정
// ---------------------------------
global.setSession();
