/* eslint-disable */
import { RouteRecordRaw } from "vue-router";
import SideMenuLayout from "@/layouts/SideMenuLayout.vue";

const routes: Array<RouteRecordRaw> = [
  {
    path: "/notice",
    meta: { layout: SideMenuLayout },
    children: [
      {
        path: "",
        name: "Dot Support Notice List",
        component: () => import(/* webpackChunkName: "notice" */ `@/views/notice/ListView.vue`),
        props: (route) => ({ query: route.query.p }),
      },
      {
        path: "new",
        name: "Dot Support Notice Write",
        component: () => import(/* webpackChunkName: "notice" */ `@/views/notice/WriteView.vue`),
      },
      {
        path: ":id",
        name: "Dot Support Notice Item",
        component: () => import(/* webpackChunkName: "notice" */ `@/views/notice/PostView.vue`),
      },
      {
        path: ":id/edit",
        name: "Dot Support Notice Modify",
        component: () => import(/* webpackChunkName: "notice" */ `@/views/notice/EditView.vue`),
      },
    ],
  },
];

export default routes;
