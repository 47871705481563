import { renderSlot as _renderSlot, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-6536c93e"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  ref: "headerSlot"
}
const _hoisted_2 = { ref: "upperMenuSlot" }
const _hoisted_3 = {
  key: 1,
  ref: "footerSlot"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_col = _resolveComponent("v-col")!
  const _component_v_row = _resolveComponent("v-row")!
  const _component_v_sheet = _resolveComponent("v-sheet")!
  const _component_v_container = _resolveComponent("v-container")!
  const _component_v_main = _resolveComponent("v-main")!
  const _component_v_app = _resolveComponent("v-app")!

  return (_openBlock(), _createBlock(_component_v_app, null, {
    default: _withCtx(() => [
      (_ctx.$slots.header)
        ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
            _renderSlot(_ctx.$slots, "header", {}, undefined, true)
          ], 512 /* NEED_PATCH */))
        : _createCommentVNode("v-if", true),
      _createVNode(_component_v_main, null, {
        default: _withCtx(() => [
          _createVNode(_component_v_container, {
            fluid: "",
            "fill-height": ""
          }, {
            default: _withCtx(() => [
              (_ctx.$slots.upperMenu)
                ? (_openBlock(), _createBlock(_component_v_row, {
                    key: 0,
                    "no-gutters": ""
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_v_col, {
                        cols: "12",
                        class: "upper-menu"
                      }, {
                        default: _withCtx(() => [
                          _createElementVNode("div", _hoisted_2, [
                            _renderSlot(_ctx.$slots, "upperMenu", {}, undefined, true)
                          ], 512 /* NEED_PATCH */)
                        ]),
                        _: 3 /* FORWARDED */
                      })
                    ]),
                    _: 3 /* FORWARDED */
                  }))
                : _createCommentVNode("v-if", true),
              _createVNode(_component_v_row, {
                "no-gutters": "",
                class: "fill-height"
              }, {
                default: _withCtx(() => [
                  (_ctx.$slots.sideMenu)
                    ? (_openBlock(), _createBlock(_component_v_col, {
                        key: 0,
                        cols: "2",
                        sm: "2",
                        md: "1",
                        class: "side-menu"
                      }, {
                        default: _withCtx(() => [
                          _renderSlot(_ctx.$slots, "sideMenu", {}, undefined, true)
                        ]),
                        _: 3 /* FORWARDED */
                      }))
                    : _createCommentVNode("v-if", true),
                  _createVNode(_component_v_col, { class: "content-sheet" }, {
                    default: _withCtx(() => [
                      _createVNode(_component_v_sheet, { class: "router-view-container" }, {
                        default: _withCtx(() => [
                          _renderSlot(_ctx.$slots, "default", {}, undefined, true)
                        ]),
                        _: 3 /* FORWARDED */
                      })
                    ]),
                    _: 3 /* FORWARDED */
                  })
                ]),
                _: 3 /* FORWARDED */
              })
            ]),
            _: 3 /* FORWARDED */
          })
        ]),
        _: 3 /* FORWARDED */
      }),
      (_ctx.$slots.footer)
        ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
            _renderSlot(_ctx.$slots, "footer", {}, undefined, true)
          ], 512 /* NEED_PATCH */))
        : _createCommentVNode("v-if", true)
    ]),
    _: 3 /* FORWARDED */
  }))
}