import { createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, vModelSelect as _vModelSelect, withDirectives as _withDirectives } from "vue"

const _hoisted_1 = { class: "input-group" }
const _hoisted_2 = ["value"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _cache[1] || (_cache[1] = _createElementVNode("label", {
      class: "form-label",
      for: "locale"
    }, "Language", -1 /* HOISTED */)),
    _withDirectives(_createElementVNode("select", {
      id: "locale",
      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.$i18n.locale) = $event)),
      "aria-label": "Select language",
      class: "form-select"
    }, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($setup.state.locales, (locale) => {
        return (_openBlock(), _createElementBlock("option", {
          key: `locale-${locale.code}`,
          value: locale.code
        }, _toDisplayString(locale.name), 9 /* TEXT, PROPS */, _hoisted_2))
      }), 128 /* KEYED_FRAGMENT */))
    ], 512 /* NEED_PATCH */), [
      [_vModelSelect, _ctx.$i18n.locale]
    ]),
    _cache[2] || (_cache[2] = _createElementVNode("div", { class: "selectArrow" }, [
      _createElementVNode("svg", {
        width: "20",
        height: "11",
        viewBox: "0 0 20 11",
        fill: "none",
        xmlns: "http://www.w3.org/2000/svg"
      }, [
        _createElementVNode("path", {
          d: "M1.66804 0.821604L10.1533 9.30688L18.6386 0.821603",
          stroke: "#44403F",
          "stroke-width": "2"
        })
      ])
    ], -1 /* HOISTED */))
  ]))
}