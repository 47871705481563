<template>
  <base-layout>
    <template #header>
      <dot-header />
    </template>
    <template #sideMenu>
      <dot-side-menu />
    </template>
    <template #default>
      <router-view />
    </template>
  </base-layout>
</template>

<script setup lang="ts">
import BaseLayout from "@/layouts/template/BaseLayout.vue";
import DotHeader from "@/layouts/inc/Header.vue";
import DotSideMenu from "@/layouts/inc/SideMenu.vue";
</script>
