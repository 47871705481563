<template>
  <v-app>
    <!-- Header -->
    <div ref="headerSlot" v-if="$slots.header">
      <slot name="header"></slot>
    </div>

    <!-- Side Menu -->
    <v-navigation-drawer v-if="$slots.sideMenu" class="side-menu" width="300">
      <slot name="sideMenu"></slot>
    </v-navigation-drawer>

    <!-- Main Content -->
    <v-main scrollable>
      <v-container class="container">
        <slot></slot>
      </v-container>
    </v-main>

    <!-- Footer -->
    <div ref="footerSlot" v-if="$slots.footer">
      <slot name="footer"></slot>
    </div>
  </v-app>
</template>

<script setup lang="ts">
import "@/assets/css/layout.css";
</script>

<style scoped>
.container {
  padding: 120px 300px;
}

.side-menu {
  border-right: 1px solid #d9d9d9;
  padding: 88px 10px 0 10px;
  text-align: left;
}
</style>
