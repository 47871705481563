<template>
  <v-btn
    variant="text"
    :ripple="false"
    style="font-size: 16px; text-transform: none; color: #212529"
    @click="login"
    v-if="!state.isLogin"
    >{{ $t("로그인") }}</v-btn
  >
  <v-menu v-else-if="state.isLogin">
    <template v-slot:activator="{ props }">
      <v-btn
        v-bind="props"
        style="
          width: 56px;
          height: 56px;
          border-radius: 50%;
          font-size: 32px;
          font-weight: bold;
          color: #fff;
          background: #aaabab;
          text-transform: initial;
          text-transform: none;
        "
        :ripple="false"
        >{{ state.userInfo.NAME }}</v-btn
      >
    </template>
    <v-list
      style="
        background: #aaabab;
        width: 160px;
        margin-top: 10px;
        box-shadow: none;
      "
    >
      <!-- 프로필 페이지 -->
      <v-list-item class="list">
        <v-list-item-title>
          <v-btn
            variant="text"
            class="listBtn"
            @click="profile"
            :ripple="false"
            >{{ $t("프로필") }}</v-btn
          >
        </v-list-item-title>
      </v-list-item>
      <!-- 프로필 페이지 -->
      <!-- 로그아웃 -->
      <v-list-item class="list">
        <v-list-item-title>
          <v-btn
            variant="text"
            class="listBtn"
            @click="logOut"
            :ripple="false"
            >{{ $t("로그아웃") }}</v-btn
          >
        </v-list-item-title>
      </v-list-item>
      <!-- 로그아웃 -->
    </v-list>
  </v-menu>
</template>

<script setup lang="ts">
import { $session } from "framework";
import { reactive } from "vue";
import { useSiteStore } from "@/store/site";
import router from "@/router";

interface UserInfo {
  NAME: string;
  USER_ID: string;
  USER_NO: string;
  USER_ROLES: {
    dotSaasVision: string;
    dotSaasClass: string;
    dotSaasSupport: string;
  };
}

interface State {
  isLogin: boolean;
  userInfo: UserInfo;
}

interface Store {
  param: {
    a: string;
    r: string;
    u: string;
  } | null;
}

const siteStore: Store = useSiteStore();

const state: State = reactive<State>({
  isLogin: $session.isLogin(),
  userInfo: {
    NAME: "",
    USER_ID: "",
    USER_NO: "",
    USER_ROLES: {
      dotSaasVision: "",
      dotSaasClass: "",
      dotSaasSupport: "",
    },
  },
});

if (state.isLogin) {
  const userName = $session.getUserInfo()?.NAME?.slice(0, 1) || "U";
  const userId = $session.getUserInfo().USER_ID;
  const userNo = $session.getUserNo();
  const userRole = $session.getUserRoles("dot-saas-support");
  state.userInfo.NAME = userName;
  state.userInfo.USER_NO = userNo;
  state.userInfo.USER_ID = userId;
  state.userInfo.USER_ROLES.dotSaasSupport = userRole;
}

const login = () => {
  router.push("/login");
};

const profile = () => {
  const sso = $session.getSsoDomain();
  const s = $session.getSiteNo();
  const c = $session.getCompNo();
  const a = siteStore.param?.a;
  const cb = window.location.origin;

  const url = `${sso}/basic?s=${s}&c=${c}&a=${a}&cb=${cb}/login-callback`;

  window.open(url);
};

const logOut = () => {
  state.isLogin = false;
  $session.signOut();
  window.location.reload();
};
</script>

<style scoped>
.list {
  min-height: 0;
  height: 38px;
  padding: 0;
  text-align: left;
}
.list:hover {
  background: #cd4a12;
}
.listBtn {
  font-size: 20px;
  font-weight: 500;
  color: #fff;
  padding: 0;
  letter-spacing: 0;
  width: 100%;
  justify-content: flex-start;
}
</style>
