import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, withCtx as _withCtx, createVNode as _createVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_btn = _resolveComponent("v-btn")!

  return (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($setup.state.menuList, (menu) => {
    return (_openBlock(), _createElementBlock("div", {
      key: menu.MENU_NAME,
      class: "d-flex flex-row"
    }, [
      _createVNode(_component_v_btn, {
        onClick: ($event: any) => ($setup.navigateToMenu(menu.MENU_URL)),
        variant: "plain",
        ripple: false,
        class: _normalizeClass(["gnb", { 'gnb-active': $setup.isActive(menu.MENU_URL) }])
      }, {
        default: _withCtx(() => [
          _createTextVNode(_toDisplayString(menu.MENU_NAME), 1 /* TEXT */)
        ]),
        _: 2 /* DYNAMIC */
      }, 1032 /* PROPS, DYNAMIC_SLOTS */, ["onClick", "class"])
    ]))
  }), 128 /* KEYED_FRAGMENT */))
}