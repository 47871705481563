<template>
  <ul class="menu-list">
    <li v-for="(menu, index) in menuList" :key="index" class="menu-item">
      <router-link
        v-if="menu.type === 'router'"
        :aria-disabled="menu.disabled ? 'true' : undefined"
        :to="menu.link"
        >{{ t(menu.title) }}
      </router-link>
      <a
        v-else-if="menu.type === 'blank'"
        :aria-disabled="menu.disabled ? 'true' : undefined"
        :href="menu.link"
        class="d-flex align-center"
        rel="noopener noreferrer"
        target="_blank"
        >{{ t(menu.title) }}
        <open-in-new />
      </a>
    </li>
  </ul>
</template>

<script setup lang="ts">
import { $session } from "framework";
import { computed, reactive } from "vue";
import OpenInNew from "@/components/atoms/icon/OpenInNew.vue";
import { useI18n } from "vue-i18n";

const { t } = useI18n();

const userRole = $session.getUserRoles()["dot-saas-support"];
const isAdmin = userRole?.includes("ADMIN") || false;

const state = reactive({
  isAdmin: isAdmin,
});

const menuList = computed(() => (state.isAdmin ? adminMenu : userMenu));

const userMenu = [
  {
    title: "공지사항",
    link: "/notice",
    type: "router",
    disabled: false,
  },
  {
    title: "유저 가이드",
    link: "https://dot-support.notion.site/fcd4b09c081b4cf4a3ae01d2442a32fe",
    type: "blank",
    disabled: false,
  },
  {
    title: "펌웨어 업데이트(USB)",
    link: "/update/firmware/connect",
    type: "router",
    disabled: false,
  },
  {
    title: "펌웨어 업데이트(Bluetooth)",
    link: "/update/firmware/bluetooth",
    type: "router",
    disabled: true,
  },
  {
    title: "자주 묻는 질문",
    link: "/faq",
    type: "router",
    disabled: false,
  },
];

const adminMenu = [
  {
    title: "공지사항",
    link: "/notice",
    type: "router",
    disabled: false,
  },
  {
    title: "유저 가이드",
    link: "https://dot-support.notion.site/fcd4b09c081b4cf4a3ae01d2442a32fe",
    type: "blank",
    disabled: false,
  },
  {
    title: "펌웨어 업데이트(USB)",
    link: "/update/firmware/connect",
    type: "router",
    disabled: false,
  },
  {
    title: "펌웨어 업데이트(Bluetooth)",
    link: "/update/firmware/bluetooth",
    type: "router",
    disabled: true,
  },
  {
    title: "자주 묻는 질문",
    link: "/faq",
    type: "router",
    disabled: false,
  },
];
</script>

<style scoped lang="scss">
.menu-list {
  display: flex;
  flex-direction: column;
  list-style: none;

  .menu-item {
    padding: 13px 17px;

    a {
      text-decoration: none;
      font-size: 21px;
      font-weight: bold;
      color: #757575;

      &:hover,
      &:focus {
        color: #cd4a12;
        outline: 2px solid #cd4a12;
        outline-offset: 4px;
        border-radius: 2px;
        text-decoration: underline;
      }

      &:focus:not(:focus-visible) {
        outline: none;
      }

      &[aria-disabled="true"] {
        pointer-events: none;
        opacity: 0.2;
      }
    }

    [aria-current="page"] {
      color: #cd4a12;
    }
  }
}
</style>
