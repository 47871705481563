import { createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock } from "vue"
import _imports_0 from '../../assets/logo.png'


export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_link = _resolveComponent("router-link")!
  const _component_v_toolbar_title = _resolveComponent("v-toolbar-title")!
  const _component_v_spacer = _resolveComponent("v-spacer")!
  const _component_v_app_bar = _resolveComponent("v-app-bar")!

  return (_openBlock(), _createBlock(_component_v_app_bar, {
    elevation: 0,
    class: "header"
  }, {
    default: _withCtx(() => [
      _createVNode(_component_v_toolbar_title, { class: "logo" }, {
        default: _withCtx(() => [
          _createCommentVNode(" 고객 지원 사이트로 이동 "),
          _createVNode(_component_router_link, {
            to: "/",
            class: "link"
          }, {
            default: _withCtx(() => _cache[0] || (_cache[0] = [
              _createElementVNode("img", {
                alt: "Dot Inc.",
                src: _imports_0
              }, null, -1 /* HOISTED */)
            ])),
            _: 1 /* STABLE */
          }),
          _createCommentVNode(" 고객 지원 사이트로 이동 ")
        ]),
        _: 1 /* STABLE */
      }),
      _createVNode(_component_v_spacer),
      _createVNode($setup["BtnMenu"]),
      _createCommentVNode(" <v-spacer></v-spacer> "),
      _createCommentVNode(" 메인 메뉴 버튼 "),
      _createCommentVNode(" <btn-menu></btn-menu> "),
      _createCommentVNode(" /메인 메뉴 버튼 "),
      _createVNode(_component_v_spacer),
      _createVNode($setup["BtnLogin"]),
      _createVNode($setup["BtnLangChange"])
    ]),
    _: 1 /* STABLE */
  }))
}