/* eslint-disable */
import { createRouter, createWebHistory, RouteRecordRaw } from "vue-router";
import DefaultLayout from "@/layouts/DefaultLayout.vue";
import routerLogin from "@/router/login";
import routerFirmware from "@/router/update";
import routerFaq from "@/router/faq";
import routerVoc from "@/router/voc";
import routerAdmin from "@/router/admin";
import routerNotice from "@/router/notice";

const routes: Array<RouteRecordRaw> = [
  {
    path: "/",
    // TODO:: 메인페이지 정해지기 전까지는 공지사항 화면으로 리다이렉트
    // name: "Main Page",
    // component: () => import(/* webpackChunkName: "home" */ "../views/main/ListView.vue"),
    // meta: { layout: DefaultLayout },
    // redirect: "/update/firmware/connect",
    redirect: "/notice",
  },
  {
    path: "/bluetooth",
    name: "Bluetooth",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/Bluetooth/HomeView.vue"),
    meta: { layout: DefaultLayout },
  },
  ...routerLogin,
  ...routerFirmware,
  ...routerVoc,
  ...routerAdmin,
  ...routerFaq,
  ...routerNotice,
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

export default router;
