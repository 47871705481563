import { defineComponent as _defineComponent } from 'vue'
import { $session } from "framework";
import { reactive } from "vue";
import { useSiteStore } from "@/store/site";
import router from "@/router";

interface UserInfo {
  NAME: string;
  USER_ID: string;
  USER_NO: string;
  USER_ROLES: {
    dotSaasVision: string;
    dotSaasClass: string;
    dotSaasSupport: string;
  };
}

interface State {
  isLogin: boolean;
  userInfo: UserInfo;
}

interface Store {
  param: {
    a: string;
    r: string;
    u: string;
  } | null;
}


export default /*@__PURE__*/_defineComponent({
  __name: 'LoginBtn',
  setup(__props, { expose: __expose }) {
  __expose();

const siteStore: Store = useSiteStore();

const state: State = reactive<State>({
  isLogin: $session.isLogin(),
  userInfo: {
    NAME: "",
    USER_ID: "",
    USER_NO: "",
    USER_ROLES: {
      dotSaasVision: "",
      dotSaasClass: "",
      dotSaasSupport: "",
    },
  },
});

if (state.isLogin) {
  const userName = $session.getUserInfo()?.NAME?.slice(0, 1) || "U";
  const userId = $session.getUserInfo().USER_ID;
  const userNo = $session.getUserNo();
  const userRole = $session.getUserRoles("dot-saas-support");
  state.userInfo.NAME = userName;
  state.userInfo.USER_NO = userNo;
  state.userInfo.USER_ID = userId;
  state.userInfo.USER_ROLES.dotSaasSupport = userRole;
}

const login = () => {
  router.push("/login");
};

const profile = () => {
  const sso = $session.getSsoDomain();
  const s = $session.getSiteNo();
  const c = $session.getCompNo();
  const a = siteStore.param?.a;
  const cb = window.location.origin;

  const url = `${sso}/basic?s=${s}&c=${c}&a=${a}&cb=${cb}/login-callback`;

  window.open(url);
};

const logOut = () => {
  state.isLogin = false;
  $session.signOut();
  window.location.reload();
};

const __returned__ = { siteStore, state, login, profile, logOut }
Object.defineProperty(__returned__, '__isScriptSetup', { enumerable: false, value: true })
return __returned__
}

})