import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, mergeProps as _mergeProps, createVNode as _createVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_btn = _resolveComponent("v-btn")!
  const _component_v_list_item_title = _resolveComponent("v-list-item-title")!
  const _component_v_list_item = _resolveComponent("v-list-item")!
  const _component_v_list = _resolveComponent("v-list")!
  const _component_v_menu = _resolveComponent("v-menu")!

  return (!$setup.state.isLogin)
    ? (_openBlock(), _createBlock(_component_v_btn, {
        key: 0,
        variant: "text",
        ripple: false,
        style: {"font-size":"16px","text-transform":"none","color":"#212529"},
        onClick: $setup.login
      }, {
        default: _withCtx(() => [
          _createTextVNode(_toDisplayString(_ctx.$t("로그인")), 1 /* TEXT */)
        ]),
        _: 1 /* STABLE */
      }))
    : ($setup.state.isLogin)
      ? (_openBlock(), _createBlock(_component_v_menu, { key: 1 }, {
          activator: _withCtx(({ props }) => [
            _createVNode(_component_v_btn, _mergeProps(props, {
              style: {"width":"56px","height":"56px","border-radius":"50%","font-size":"32px","font-weight":"bold","color":"#fff","background":"#aaabab","text-transform":"none"},
              ripple: false
            }), {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString($setup.state.userInfo.NAME), 1 /* TEXT */)
              ]),
              _: 2 /* DYNAMIC */
            }, 1040 /* FULL_PROPS, DYNAMIC_SLOTS */)
          ]),
          default: _withCtx(() => [
            _createVNode(_component_v_list, { style: {"background":"#aaabab","width":"160px","margin-top":"10px","box-shadow":"none"} }, {
              default: _withCtx(() => [
                _createCommentVNode(" 프로필 페이지 "),
                _createVNode(_component_v_list_item, { class: "list" }, {
                  default: _withCtx(() => [
                    _createVNode(_component_v_list_item_title, null, {
                      default: _withCtx(() => [
                        _createVNode(_component_v_btn, {
                          variant: "text",
                          class: "listBtn",
                          onClick: $setup.profile,
                          ripple: false
                        }, {
                          default: _withCtx(() => [
                            _createTextVNode(_toDisplayString(_ctx.$t("프로필")), 1 /* TEXT */)
                          ]),
                          _: 1 /* STABLE */
                        })
                      ]),
                      _: 1 /* STABLE */
                    })
                  ]),
                  _: 1 /* STABLE */
                }),
                _createCommentVNode(" 프로필 페이지 "),
                _createCommentVNode(" 로그아웃 "),
                _createVNode(_component_v_list_item, { class: "list" }, {
                  default: _withCtx(() => [
                    _createVNode(_component_v_list_item_title, null, {
                      default: _withCtx(() => [
                        _createVNode(_component_v_btn, {
                          variant: "text",
                          class: "listBtn",
                          onClick: $setup.logOut,
                          ripple: false
                        }, {
                          default: _withCtx(() => [
                            _createTextVNode(_toDisplayString(_ctx.$t("로그아웃")), 1 /* TEXT */)
                          ]),
                          _: 1 /* STABLE */
                        })
                      ]),
                      _: 1 /* STABLE */
                    })
                  ]),
                  _: 1 /* STABLE */
                }),
                _createCommentVNode(" 로그아웃 ")
              ]),
              _: 1 /* STABLE */
            })
          ]),
          _: 1 /* STABLE */
        }))
      : _createCommentVNode("v-if", true)
}