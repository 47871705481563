import { defineComponent as _defineComponent } from 'vue'
import { ref, watch } from "vue";
import { useRoute } from "vue-router";


export default /*@__PURE__*/_defineComponent({
  __name: 'App',
  setup(__props, { expose: __expose }) {
  __expose();

const route = useRoute();
const layout = ref();

watch(
  () => route.meta.layout,
  (newLayout) => {
    layout.value = newLayout;
  }
);

const __returned__ = { route, layout }
Object.defineProperty(__returned__, '__isScriptSetup', { enumerable: false, value: true })
return __returned__
}

})