<template>
  <div class="input-group">
    <label class="form-label" for="locale">Language</label>
    <select
      id="locale"
      v-model="$i18n.locale"
      aria-label="Select language"
      class="form-select"
    >
      <option
        v-for="locale in state.locales"
        :key="`locale-${locale.code}`"
        :value="locale.code"
      >
        {{ locale.name }}
      </option>
    </select>
    <div class="selectArrow">
      <svg
        width="20"
        height="11"
        viewBox="0 0 20 11"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M1.66804 0.821604L10.1533 9.30688L18.6386 0.821603"
          stroke="#44403F"
          stroke-width="2"
        />
      </svg>
    </div>
  </div>
</template>

<script setup lang="ts">
import { reactive } from "vue";

const state = reactive({
  locales: [
    { code: "ko", name: "한국어" },
    { code: "ar", name: "اللغة العربية" },
    { code: "cz", name: "Czech" },
    { code: "de", name: "German" },
    { code: "en", name: "Eng" },
    { code: "es", name: "Español" },
    { code: "fr", name: "Français" },
    { code: "ja", name: "日本語" },
    { code: "kz", name: "қазақ" },
    { code: "pt", name: "Português" },
    { code: "sv", name: "Svenska" },
  ],
});
</script>

<style scoped>
.input-group {
  margin-left: 10px;
  text-align: left;
}

.input-group label {
  font-size: 1rem;
  color: #212529;
  font-weight: bold;
}

.form-select {
  padding: 8px 0;
  font-weight: bold;
  width: 100%;
  appearance: none;
  background: transparent;
  z-index: 3;
}

.selectArrow {
  position: absolute;
  top: 33px;
  right: 44px;
  z-index: -1;
  cursor: pointer !important;
}
</style>
